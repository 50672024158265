import React from 'react'

const Policies = () => {
    return(
        <div>
             <div className="max-w-4xl mx-auto p-8 space-y-8">
                <div className="text-3xl font-bold mb-2 text-center">Synapsed Policies</div>
      {/* Shipping Policy */}
      <div className="bg-white bg-opacity-20 backdrop-blur-lg border border-white border-opacity-30 rounded-xl p-6 shadow-lg">
        <h1 className="text-3xl font-bold mb-4" style={{color: "#ba41a3"}}>Synapsed Infotech Shipping Policy</h1>
        <p className="text-lg text-white">
          At Synapsed Infotech Pvt Ltd, our products are digital goods. Upon purchase, customers receive their product instantly via email.
          There are no physical shipments. For any inquiries, please contact us at{' '}
          <a href="mailto:synapsedinfotech@gmail.com" className="text-blue-500 underline">synapsedinfotech@gmail.com</a>.
        </p>
      </div>

      {/* Terms and Conditions */}
      <div className="bg-white bg-opacity-20 backdrop-blur-lg border border-white border-opacity-30 rounded-xl p-6 shadow-lg">
        <h1 className="text-3xl font-bold mb-4" style={{color: "#ba41a3"}}>Synapsed Infotech Terms and Conditions</h1>
        <h2 className="text-2xl font-semibold mb-3 text-gray-500">Introduction</h2>
        <p className="text-lg text-white">
          Welcome to Synapsed Infotech Pvt Ltd. By accessing our services, you agree to comply with our terms and conditions. These terms govern
          your use of our digital products and services.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Use of Services</h2>
        <p className="text-lg text-white">
          Our digital products are intended for lawful use only. Unauthorized copying, distribution, or modification is prohibited.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Payment and Delivery</h2>
        <p className="text-lg text-white">
          Payments are processed securely, and products are delivered instantly via email upon purchase. Ensure your email address is accurate to
          receive your digital goods.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Modifications</h2>
        <p className="text-lg text-white">
          We reserve the right to modify these terms at any time. Changes will be communicated via email or our website.
        </p>
      </div>

      {/* Privacy Policy */}
      <div className="bg-white bg-opacity-20 backdrop-blur-lg border border-white border-opacity-30 rounded-xl p-6 shadow-lg">
        <h1 className="text-3xl font-bold mb-4" style={{color: "#ba41a3"}}>Synapsed Infotech Privacy Policy</h1>
        <h2 className="text-2xl font-semibold mb-3 text-gray-500">Introduction</h2>
        <p className="text-lg text-white">
          Synapsed Infotech Pvt Ltd is committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Information Collection</h2>
        <p className="text-lg text-white">
          We collect personal information such as your name, email address, and payment details during the purchase process.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Use of Information</h2>
        <p className="text-lg text-white">
          Your information is used to process transactions, deliver digital products, and improve our services. We do not share your data with third parties.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">Data Security</h2>
        <p className="text-lg text-white">
          We implement robust security measures to protect your information from unauthorized access and breaches.
        </p>
      </div>

      {/* Cancellation Policy */}
      <div className="bg-white bg-opacity-20 backdrop-blur-lg border border-white border-opacity-30 rounded-xl p-6 shadow-lg">
        <h1 className="text-3xl font-bold mb-4" style={{color: "#ba41a3"}}>Synapsed Infotech Cancellation Policy</h1>
        <h2 className="text-2xl font-semibold mb-3 text-gray-500">Introduction</h2>
        <p className="text-lg text-white">
          At Synapsed Infotech Pvt Ltd, we prioritize customer satisfaction. However, due to the nature of digital goods, we maintain a strict cancellation policy.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3 text-gray-500">No Refunds</h2>
        <p className="text-lg text-white">
          All sales are final. Once a digital product is purchased and delivered via email, refunds are not available. Please ensure you review your order carefully before completing your purchase.
        </p>
      </div>
    </div>
        </div>
    )
}


export default Policies;
