import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import aos
import Aos from "aos";
import "aos/dist/aos.css";
// import components
import Header from "./components/Header";
import Hero from "./components/Hero";
import NavMobile from "./components/NavMobile";
import Footer from "./components/Footer";
import NetworkModel from "./components/NetworkModel";
import ComingSoon from "./components/ComingSoon";
import Web3 from "./components/Web3";
import AboutUs from "./components/AboutUs";
import HowItWorks from "./components/HowItWorks";
import ContactForm from "./components/ContactForm";
import Platform from "./components/Platform";
import Integra from "./components/IntegraSection";
import IntegraInformation from "./components/IntegraInformation";
import StorefrontCarousel from "./components/StorefrontCarousel";
import IntegraShop from "./components/IntegraShop";
import Policies from "./components/Policies";

const App = () => {
  const loadRazorpayScript = (src, onLoad) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        onLoad();
        resolve(true);
      };
      script.onerror = (err) => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // mobile nav state
  const [navMobile, setNavMobile] = useState(false);

  // aos init
  useEffect(() => {
    loadRazorpayScript("https://checkout.razorpay.com/v1/checkout.js", () => {
      console.log("Razorpay SDK loaded.");
    });
    Aos.init({
      duration: 2500,
      delay: 400,
    });
  });

  return (
    <BrowserRouter>
      <div>
        <div
          className={`${
            navMobile ? "right-0" : "-right-full"
          } fixed z-10 top-0 h-full transition-all duration-200`}
        >
          <NavMobile setNavMobile={setNavMobile} />
        </div>
        <Header setNavMobile={setNavMobile} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <IntegraInformation />
                <Integra />
                <StorefrontCarousel />
                {/* <Platform /> */}
                <NetworkModel />
                <Web3 />
                <ComingSoon />
                <AboutUs />
                <HowItWorks />
                <ContactForm />
              </>
            }
          />
          <Route path="/shop" element={<IntegraShop />} />
          <Route path="/policies" element={<Policies />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
